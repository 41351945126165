import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { setLeadEmployeeReport } from '../pages/Dashboard/Dependencies/action'
import { useDispatch, useSelector } from 'react-redux';
import { getAttendenceRecord } from '../pages/Attendence/ManageMarkAttendence/Dependencies/action';
import moment from 'moment';

const AbsentEmployees = () => {

    const dispatch: any = useDispatch();
    const [absent, setAbsent] = useState([]);

    const { users } = useSelector((state: any) => state.userReducer);
    useEffect(() => {
        // dispatch(setLeadEmployeeReport())
        const payload = {
            firstdate: moment().format('YYYY-MM-DD'),
            lastdate: moment().format('YYYY-MM-DD')
        }
        getAttendenceRecord(payload).then((res) => {
            console.log(res.data);
            if (res?.data && res.data.length > 0) {
                const data = res.data.filter((d) => d.attendence_message === "L" || d.attendence_message === "PL" || d.attendence_message === "P")
                setAbsent(data);
            }
        })
    }, [])


    return <>
        <h1 className='font-bold text-center text-2xl my-10 underline'>Leave Board</h1>
        <TableContainer component={Paper} className='mt-5 h-[27rem]'>
            <Table className='w-full table-auto'>
                <TableHead>
                    <TableRow className='bg-gray-2 text-left dark:bg-meta-4'>
                        <TableCell className="min-w-[120px] py-4 px-4 font-bold text-black dark:text-white">
                            Name
                        </TableCell>
                        <TableCell className="min-w-[180px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                            Absent
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.length > 0 && users.map((user, id) => (
                        <TableRow key={id}>
                            <TableCell className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                <h5 className="font-medium text-black dark:text-white">
                                    {user.user_name}
                                </h5>
                            </TableCell>
                            <TableCell className="border-b border-[#eee] cursor-pointer py-5 px-4 pl-9 dark:border-strokedark xl:pl-11" >
                                <h5 className="font-medium text-black hover:text-[#e1ad01] dark:text-white">
                                    <p className="text-black dark:text-white">
                                        {absent.find((d) => d.user_id == user.user_id)?.attendence_message === "L" ? "Absent" : absent.find((d) => d.user_id == user.user_id)?.attendence_message === "P" ? "Present" : "Not Available"}</p>
                                </h5>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>

}

export default AbsentEmployees