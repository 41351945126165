import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';

const RobinHoodOrderAssign = ({ multiOptions, multiOptionsProduct, label, takeSelectedData, AssignData }) => {
    const [userSelected, setUserSelected] = useState([]);
    const [productSelected, setProductSelected] = useState([]);

    const [formatSelected, setFormatSelected] = useState([]);
    const [verified, setVerified] = useState([]);

    const [assignUserDefault, setAssignUserDefault] = useState([]);
    const [assignVerifiedDefault, setAssignVerifiedDefault] = useState([]);
    const [,] = useState([]);

    const [start, setStart] = useState(false);

    const selectedFormat: any = [
        { value: '', label: 'None' },
        { value: "ESX- Sketch Only", label: "ESX- Sketch Only" },
        { value: "XML- Only", label: "XML- Only" },
        { value: "PDF- Only", label: "PDF- Only" },
        { value: "ESX- Sketch with .ESX PDF File", label: "ESX- Sketch with .ESX PDF File" },
        { value: "PDF- with Material List", label: "PDF- with Material List" },
        { value: "XML- with PDF", label: "XML- with PDF" },
        { value: "ESX", label: "ESX" },
        { value: "XML", label: "XML" }
    ]

    useEffect(() => {
        if (AssignData?.assign_id) {
            setProductSelected(AssignData?.assign_products ? JSON.parse(AssignData.assign_products).map(d => { return { value: d, label: d } }) : []);
            setUserSelected(AssignData?.assign_users ? JSON.parse(AssignData.assign_users).map(d => { return { value: d, label: multiOptions.filter(d2 => d === d2.value)[0]?.label } }) : []);
            setFormatSelected(AssignData.assign_format ? JSON.parse(AssignData.assign_format).map(d => { return { value: d, label: d } }) : []);
            setVerified(AssignData?.assign_verified_users ? JSON.parse(AssignData.assign_verified_users).map(d => { return { value: d, label: multiOptions.filter(d2 => d === d2.value)[0]?.label } }) : [])
            setAssignUserDefault(AssignData?.assign_user_default ? JSON.parse(AssignData.assign_user_default).map(d => { return { value: d, label: multiOptions.filter(d2 => d === d2.value)[0]?.label } }) : []);
            setAssignVerifiedDefault(AssignData?.assign_verified_default ? JSON.parse(AssignData.assign_verified_default).map(d => { return { value: d, label: multiOptions.filter(d2 => d === d2.value)[0]?.label } }) : []);
            AssignData.assign_id ? setStart(true) : setStart(false);
        }
    }, []);
    return (
        <div>
            <h1 className='text-lg my-3'>Auto Assign Orders ({label})</h1>
            <div className="grid grid-cols-12 gap-3">
                <div className="col-span-12 md:col-span-2">
                    <label>Select Assign Users</label>
                    <MultiSelect
                        options={multiOptions}
                        value={userSelected}
                        onChange={setUserSelected}
                        labelledBy="Select Assign Users"
                    />
                </div>
                <div className="col-span-12 md:col-span-2">
                    <label>Select Products</label>
                    <MultiSelect
                        options={multiOptionsProduct}
                        value={productSelected}
                        onChange={setProductSelected}
                        labelledBy="Select Products"
                    />
                </div>
                <div className="col-span-12 md:col-span-2">
                    <label>Select Format</label>
                    <MultiSelect
                        options={selectedFormat}
                        value={formatSelected}
                        onChange={setFormatSelected}
                        labelledBy="Select Format"
                    />
                </div>
                <div className="col-span-12 md:col-span-2">
                    <label>Select Verifyers</label>
                    <MultiSelect
                        options={multiOptions}
                        value={verified}
                        onChange={setVerified}
                        labelledBy="Select Verify Users"
                    />
                </div>
                <div className="col-span-12 md:col-span-2">
                    <label>Default Assign Users</label>
                    <MultiSelect
                        options={multiOptions}
                        value={assignUserDefault}
                        onChange={setAssignUserDefault}
                        labelledBy="Default Assign Users"
                    />
                </div>
                <div className="col-span-12 md:col-span-2">
                    <label>Default Verifyer Users</label>
                    <MultiSelect
                        options={multiOptions}
                        value={assignVerifiedDefault}
                        onChange={setAssignVerifiedDefault}
                        labelledBy="Default Verifer Users"
                    />
                </div>
                {!start ? <button onClick={() => {
                    console.log(userSelected)
                    if (userSelected.length <= 0 && productSelected.length <= 0) {
                        alert('Please Select User & Product');
                        return
                    }
                    setStart(true);
                    takeSelectedData(userSelected, productSelected, true, 0, formatSelected, verified, assignUserDefault, assignVerifiedDefault);
                }} className="flex w-full col-span-12 md:col-span-2 justify-center rounded bg-primary p-2 font-medium text-gray" type="submit">
                    Start
                </button> : <>{<button onClick={() => {
                    setStart(false);
                    setUserSelected([]);
                    setProductSelected([]);
                    takeSelectedData(userSelected, productSelected, false, AssignData.assign_id, formatSelected, null);
                }} className="flex w-full col-span-12 md:col-span-2 justify-center rounded bg-danger p-2 font-medium text-gray" type="submit">
                    Stop
                </button>}
                    <button onClick={() => {
                        takeSelectedData(userSelected, productSelected, true, AssignData.assign_id, formatSelected, verified, assignUserDefault, assignVerifiedDefault);
                    }} className="flex w-full col-span-12 md:col-span-2 justify-center rounded bg-success p-2 font-medium text-gray" type="submit">
                        Edit
                    </button></>}
            </div>
        </div>
    )
}

export default RobinHoodOrderAssign